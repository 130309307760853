import React, { useState } from "react"
import Layout from "../../../components/Layout"
import Hero from "../../../components/Hero"
import Breadcrumb from "../../../components/Breadcrumb"
import Searcher from "../../../components/Searcher"
import TitleBlock from "../../../components/TitleBlock"
import Hours from "../../../components/Hours"
import CollapseMenu from "../../../components/Collapse/CollapseMenu"
import { menuOptions } from "../../../data/layout-test-data"
import Calendar from "../../../components/Calendar"
import Schedule from "../../../components/Schedule"
import {
  info,
  scheduleData,
  scheduleTypesData,
} from "../../../data/services-activities-data"
import Pagination from "../../../components/Pagination"
import Event from "../../../components/Event"

export default function WebTest() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Servicios y actividades", to: "/servicios-y-actividades" },
    { label: "Exámenes" },
  ]
  const [currentPage, setCurrentPage] = useState(1)

  const getPage = page => {
    setCurrentPage(page)
  }
  const renderEvents = () => {
    return (
      info &&
      info.map((item, i) => (
        <Event
          title={item.title}
          path={item.path}
          subtitle={item.subtitle}
          key={`event-${i}`}
        />
      ))
    )
  }
  return (
    <Layout>
      <Hero image="/imgs/servicios-actividades-ret.jpg" text="Exámenes" />
      <div className="container950">
        <Breadcrumb path={path} />
        <Searcher />
        <div className="row efpa-row">
          <div className="col-12 col-md-3 Topicality-padding-col">
            <CollapseMenu list={menuOptions} title="Menú" />
            <div className="d-none d-md-block">
              <Hours
                list={menuOptions}
                showTitle={false}
                className="LayoutTest-options"
              />
            </div>
          </div>
          <div className="col-12 col-md-9 Topicality-col-9">
            <TitleBlock title="Exámenes" icon="far fa-calendar-alt" />
            <div className="row efpa-row">
              <div className="col-12 col-md-6 Topicality-col-9">
                <Calendar className="WebTest-calendar" />
              </div>
              <div className="col-12 col-md-6 Topicality-col-9">
                <Schedule
                  title="Próximas actividades"
                  scheduleList={scheduleData}
                  types={scheduleTypesData}
                />
              </div>
            </div>
            <p className="WebExam-title">Actividades anteriores:</p>
            <Pagination
              totalPages={30}
              currentPage={currentPage}
              getPage={getPage}
              className="WebExam-pagination"
            />
            <div className="WebExam-wrapper">{renderEvents()}</div>
            <Pagination
              totalPages={30}
              currentPage={currentPage}
              getPage={getPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
