export const menuOptions = [
  {
    text: "Exámenes",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#20307d",
  },
  {
    text: "Conferencias EFPA",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#ffed00",
  },
  {
    text: "#JornadasEFPA",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#db0059",
  },
  {
    text: "EFPA Congress",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#1E90FF",
  },
  {
    text: "Conferencias externas",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#00eae4",
  },
  {
    text: "Ferias",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#ffb600",
  },
  {
    text: "Otros actos",
    link: "/servicios-y-actividades/examenes-web",
    borderColor: "#26ff00",
  },
]

export const docsOptions = [
  {
    text: "Fichero 1 de ejemplo",
    icon: "fas fa-file-alt",
  },
  {
    text: "Fichero 2 de ejemplo",
    icon: "fas fa-file-alt",
  },
]
