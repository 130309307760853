export const info = [
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
  {
    subtitle: "02/10/2017 - 16:00 a 20:00 - Murcia",
    title: "Sesión de Recertificación Murcia (2 de octubre)",
    path: "",
  },
]

export const scheduleData = [
  {
    subtitle: "19/02/2021",
    title: "1a Convocatoria 2021 examen EFP (European Financial Planner)",
    path:
      "/servicios-y-actividades/examenes-web/1a-convocatoria-examen-efp-2021",
  },
  {
    subtitle: "19/02/2021",
    title: "2a Convocatoria 2021 examen EFP (European Financial Planner)",
    path:
      "/servicios-y-actividades/examenes-web/2a-convocatoria-examen-efp-2021",
  },
  {
    subtitle: "19/02/2021",
    title: "3a Convocatoria 2021 examen EFP (European Financial Planner)",
    path:
      "/servicios-y-actividades/examenes-web/3a-convocatoria-examen-efp-2021",
  },
  {
    subtitle: "19/02/2021",
    title: "1a Convocatoria 2021 examen EFP (European Financial Planner)",
    path: "/",
  },
  {
    subtitle: "19/02/2021",
    title: "1a Convocatoria 2021 examen EFP (European Financial Planner)",
    path: "/",
  },
  {
    subtitle: "19/02/2021",
    title: "1a Convocatoria 2021 examen EFP (European Financial Planner)",
    path: "/",
  },
]

export const scheduleTypesData = [
  {
    color: "yellow",
    text: "Exámenes",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "green",
    text: "Conferencias EFPA",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "gray",
    text: "#JornadasEFPA",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "black",
    text: "EFPA Congress",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "yellow",
    text: "Conferencias externas",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "yellow",
    text: "Ferias",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "yellow",
    text: "Otros actos",
    path: "/servicios-y-actividades/examenes-web",
  },
  {
    color: "yellow",
    text: "Webinar",
    path: "/servicios-y-actividades/examenes-web",
  },
]
